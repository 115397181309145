<style lang="less" scoped>
.T-Preview-CheckWork-Module {
  .stu-module-header {
    padding: 20px;
    background: #fff;
    box-shadow: @shadow;
    margin-bottom: 15px;
    .stu-module-title {
      span {
        display: inline-block;
        margin-right: 20px;
        font-weight: 500;
      }
    }
  }
  .answer_container {
    min-height: 100px;
    padding: 20px;
    .checked_btn_group {
      margin-bottom: 20px;
      background: #fff;
      padding: 18px;
      > span {
        display: inline-block;
        margin-right: 10px;
      }
    }
    .total_group {
      background: #fff;
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: @shadow;
      font-weight: bold;
      .title {
        font-size: 20px;
        span {
          color: @mainColor;
          display: inline-block;
          margin-right: 20px;
        }
      }
    }
    .answer_content {
      .el-collapse-item {
      }
      .collapse_header {
        font-size: 16px;
        display: inline-block;
        padding-left: 20px;
      }
      .collapse_content {
        .answer_desc {
          background-color: #f2f2f2;
          font-size: 16px;
          font-weight: bold;
          color: #3b4553;
          padding: 8px 20px;
        }
        .answer_items {
          .items_title_group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
            padding: 20px;
            > div:first-child {
              display: flex;
              align-items: center;
            }
            .give_score {
              font-weight: bold;
              font-size: 18px;
              margin-right: 30px;
            }
          }

          .items_radio {
            margin-left: 50px;
            .el-radio {
              display: block;
              margin-bottom: 15px;
            }
          }
          .items_radio_check {
            display: flex;
            align-items: center;
          }
          .input_number {
            width: 80px;
            .el-input__inner {
              padding-left: 5px !important;
              padding-right: 40px !important;
            }
          }
          .items_answer_group {
            padding-left: 50px;
            > div:first-child {
              margin-bottom: 20px;
              p {
                display: inline-block;
                margin-right: 50px;
                font-size: 16px;
                font-weight: 500;
                color: #999;
              }
            }
            .parse {
              font-size: 16px;
              color: #999;
              font-weight: 500;
            }
          }
        }
        .success {
          color: #77c325;
        }
        .error {
          color: #af3737;
        }
      }
    }
  }
}
</style>
<template>
  <div class="T-Preview-CheckWork-Module">
    <el-row v-loading="loading">
      <el-col :md="17" :lg="18" class="answer_container">
        <div class="total_group">
          <div class="title">
            姓名：<span>{{ data.studentName }}</span> 总得分：<span>{{
              data.stuCore
            }}</span>
            作业名称：<span>{{ data.workName }}</span>
          </div>
        </div>
        <div class="answer_content">
          <el-collapse>
            <el-collapse-item
              v-for="(item, index) in data.eduTestPaperQuestions"
              :key="index"
              :name="item.id"
            >
              <template slot="title">
                <span class="collapse_header">{{ item.questionName }}</span>
              </template>
              <div class="collapse_content">
                <div class="answer_desc">答题要求：{{ item.answerDesc }}</div>
                <div
                  class="answer_items"
                  v-for="(s, i) in item.eduCourseQuestionVos"
                  :key="i"
                >
                  <div class="items_title_group">
                    <div>
                      <p style="width: 28px; height: 28px">
                        <svg-icon
                          v-if="s.enabled === 1"
                          :name="
                            s.correctFlag === 1
                              ? 'success'
                              : s.correctFlag === 0
                              ? 'error'
                              : ''
                          "
                        ></svg-icon>
                      </p>
                      <p>{{ i + 1 }}. ({{ s.itemScore }}分)&nbsp;&nbsp;</p>
                      <div><div v-html="s.questionTitle"></div></div>
                    </div>
                    <p
                      v-if="s.enabled === 1"
                      class="give_score"
                      :class="
                        s.correctFlag === 1 || s.stuItemScore
                          ? 'success'
                          : s.correctFlag === 0 || !s.stuItemScore
                          ? 'error'
                          : ''
                      "
                    >
                      +{{ s.stuItemScore }}分
                    </p>
                  </div>
                  <el-radio-group class="items_radio">
                    <el-radio
                      v-for="(r, i) in s.eduCourseQuestionOps"
                      :key="i"
                      :label="r.id"
                      disabled
                    >
                      {{ r.itemKey }}. {{ r.itemVal }}
                    </el-radio>
                  </el-radio-group>
                  <div class="items_answer_group">
                    <div>
                      <div>
                        我的答案：
                        <div v-html="s.answerVal"></div>
                      </div>
                      <div>
                        参考答案：
                        <div class="success" v-html="s.questionKey"></div>
                      </div>
                    </div>
                    <div class="parse">
                      答案解析：<br />
                      <div v-html="s.questionAnalyze"></div>
                    </div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
      <!-- 批阅 -->
      <el-col
        :md="7"
        :lg="6"
        class="answer_container"
        style="padding: 20px 0px"
      >
        <div class="checked_btn_group">
          <span>得分：{{ data.stuCore }}</span>
          <el-button type="primary" :loading="btnLoading" @click="onChecked"
            >保存</el-button
          >
          <el-button type="primary" @click="back">返回</el-button>
        </div>
        <div class="answer_content">
          <el-collapse>
            <el-collapse-item
              v-for="(item, index) in data.eduTestPaperQuestions"
              :key="index"
              :name="item.id"
            >
              <template slot="title">
                <span class="collapse_header">{{ item.questionName }}</span>
              </template>
              <div class="collapse_content">
                <div
                  class="answer_items"
                  v-for="(s, i) in item.eduCourseQuestionVos"
                  :key="i"
                >
                  <div class="items_title_group" style="padding-bottom: 0">
                    <div>
                      <p>{{ i + 1 }}. ({{ s.itemScore }}分)&nbsp;&nbsp;</p>
                    </div>

                    <div v-if="s.enabled === 0">
                      <el-radio-group
                        class="items_radio_check"
                        v-model="s.user_checked"
                        v-if="s.eduCourseQuestionOps.length"
                      >
                        <el-radio :label="1">正确</el-radio>
                        <el-radio :label="0">错误</el-radio>
                      </el-radio-group>
                      <p v-else>
                        <span>得分：</span>
                        <el-input-number
                          class="input_number"
                          v-model="s.user_score"
                          controls-position="right"
                          :min="0"
                          :max="s.itemScore"
                        ></el-input-number>
                      </p>
                    </div>
                    <p
                      v-else
                      class="give_score"
                      :class="
                        s.correctFlag === 1 || s.stuItemScore
                          ? 'success'
                          : s.correctFlag === 0 || !s.stuItemScore
                          ? 'error'
                          : ''
                      "
                    >
                      +{{ s.stuItemScore }}分
                    </p>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  QueryStudentWorkById, // 批阅 - 查看
  TeacherReviewEduCourseWork, // 批阅
} from "@/libs/api/teacher/check";
export default {
  name: "t-preview-checkWork-module",
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      data: {},
      total_score: 0,
    };
  },
  computed: {
    clearTags() {
      return (val) => val.replace(/<[^>]+>/g, "");
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      const { id, studentId } = this.$route.query;
      QueryStudentWorkById({ id, studentId })
        .then((res) => {
          this.loading = false;
          if (res.data) this.data = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChecked() {
      const list = this.data.eduTestPaperQuestions;
      let eduTestPaperItems = [];
      for (const item of list) {
        for (let i = 0; i < item.eduCourseQuestionVos.length; i++) {
          const v = item.eduCourseQuestionVos[i];
          const isLen = v.eduCourseQuestionOps.length;
          // 是选择题，没有进行选择
          const isSelect = isLen && !v.user_checked && v.user_checked !== 0;
          // 不是选择题，没有输入得分
          const noSelect = !isLen && !v.user_score && v.user_score !== 0;
          // 0没有批阅， 1已批阅
          if (v.enabled === 0 && (isSelect || noSelect)) {
            this.$message.error(
              `${item.questionName}中的第${i + 1}题未进行批阅，请选择`
            );
            return;
          }
          if (v.enabled === 0) {
            let obj = {
              questionId: v.id, // 题目主键id
              itemScore: isLen
                ? v.user_checked === 1
                  ? v.itemScore
                  : 0
                : v.user_score, //题目获得分数
            };
            // 所答是否正确 0否 1是 (只有选择题时传)
            if (v.user_checked || v.user_checked === 0) {
              obj.correctFlag = v.user_checked;
            }
            eduTestPaperItems.push(obj);
            this.total_score += isLen
              ? v.user_checked === 1
                ? v.itemScore
                : 0
              : v.user_score;
          } else {
            this.total_score += v.stuItemScore;
          }
        }
      }
      if (eduTestPaperItems.length) {
        this.btnLoading = true;
        const { id } = this.$route.query;
        TeacherReviewEduCourseWork({ id, eduTestPaperItems })
          .then((res) => {
            this.$message.success("批阅成功");
            this.btnLoading = false;
            this.init();
            this.back();
          })
          .catch(() => {
            this.btnLoading = false;
          });
      } else {
        this.$message.warning("当前无批阅题目");
      }
    },
    back() {
      this.$router.push({
        path: "/teacher/t/work/online/detail",
        query: {},
      });
    },
  },
};
</script>
